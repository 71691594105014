<template>
  <div class="hello">

  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default {
  name: 'HelloWorld',
  created(){
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      return result.visitorId; // 唯一标识符
    };

    getFingerprint().then(visitorId => {
      console.log('Visitor ID:', visitorId);
    });
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
